import * as React from 'react'
import styled, { css } from 'styled-components'
import { darken } from 'polished'

interface IAccordionProps {
  open: boolean
}

const Faq = styled<IAccordionProps, any>('article')`
  position: relative;
  padding-left: 2rem;

  & + article {
    margin-top: 1rem;
  }

  @media (min-width: 940px) {
    & + article {
      margin-top: 1.5rem;
    }
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    background-color: ${props => darken(0.05, props.theme.colors.primary)};
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  &:before {
    top: 9px;
    left: 0;
    width: 12px;
    height: 2px;
  }
  &:after {
    top: 4px;
    left: 5px;
    height: 12px;
    width: 2px;
  }

  ${props =>
    props.open &&
    css`
      ${Answer} {
        display: block;
      }
      &:after {
        top: 9px;
        height: 2px;
      }
    `};
`

const Question = styled.button`
  color: ${props => darken(0.05, props.theme.colors.primary)};
  display: block;
  width: 100%;
  text-align: left;
  font-size: 1.15em;

  a {
    text-decoration: underline;
  }

  @media (min-width: 940px) {
    font-size: 1.25em;
  }
`

const Answer = styled.div`
  margin-top: 0.75rem;
  display: none;

  @media (min-width: 940px) {
    margin-top: 1rem;
  }
`

interface AccordionProps {
  question: string
  isHtml: boolean
  answer: string
}

interface State {
  open: boolean
}

export default class extends React.PureComponent<AccordionProps, State> {
  state = {
    open: false
  }

  openAnswer = () => this.setState({ open: !this.state.open })

  render() {
    const { answer, question, isHtml } = this.props

    return (
      <Faq open={this.state.open}>
        <Question
          onClick={this.openAnswer}
          dangerouslySetInnerHTML={{ __html: question }}
        />
        <Answer>
          {isHtml ? (
            <div dangerouslySetInnerHTML={{ __html: answer }} />
          ) : (
            <p>{answer}</p>
          )}
        </Answer>
      </Faq>
    )
  }
}
