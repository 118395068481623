import * as React from 'react'
import styled, { css } from 'styled-components'
import { xPad, yPad, yMargin } from '../utils/theme'
import Accordion from './Accordion'
import { darken } from 'polished'

interface IFAQContainerProps {
  flushTop: boolean
}

export const FAQContainer = styled<IFAQContainerProps, any>('div')`
  background-color: #f9f8f7;
  ${yMargin};
  margin-bottom: 0 !important;

  h3 i {
    color: ${props => props.theme.colors.primary};
    font-style: normal;
  }

  ${props =>
    props.background &&
    css`
      /* background-image: url(${props.background}); */
      background-color: #171717;
      background-size: cover;
      background-repeat: none;
      background-position: center center;
      h3,
      button,
      p {
        color: #fff !important;
      }
    `};

  ${props =>
    props.flushTop &&
    css`
      margin-top: 0 !important;
    `};
`

export const FAQs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin: 0 auto;
  max-width: 1000px;

  ${yPad};
  ${xPad};
  a {
    text-decoration: underline;
  }

  h3 {
    color: ${props => darken(0.4, props.theme.colors.primary)};
    &:not(:first-child) {
      padding-top: 2em;
    }
  }
`

interface IFAQ {
  title?: string
  answer?: string
  question?: string
  isHtml?: boolea
}

interface IFAQProps {
  flushTop?: boolean
  title: string
  data: IFAQ[]
}

const data = [
  {
    question:
      '<p>Am I under any obligation in receiving a <a href="/on-site-quote/">free quote?</a>',
    answer:
      'No, not at all. Our obligation free quotes are part of our service and are always completely free.'
  },
  {
    question:
      'Do you take care of <a href="/council-approval/">council permits</a>, relaxations and plans?',
    answer:
      'Yes. Our certifier will arrange all documents required for the application including the final inspection.'
  },
  {
    question: 'Do I receive a copy of my approved plans?',
    answer:
      'Yes. A copy is sent to the owner, the local council and Just Patios directly from our certifier for record keeping.'
  },
  {
    question:
      'What warranty do I get with my <a href="/patio-builders-brisbane/">patio</a> or carport?',
    answer:
      'Depending on the product you choose to have installed, it can be as high as 15 years.'
  },
  {
    question: 'If I go ahead with the quote how much deposit will I pay?',
    answer: 'In most situations the deposit is 10% of the contract price.'
  },
  {
    question: 'Do I need to arrange anything?',
    answer:
      'No. We will obtain all approvals, do all site preparations, complete construction and arrange for all inspections.'
  },
  {
    question: 'How long will it take to get my patio built?',
    answer:
      'In most cases it will take between 2-8 weeks depending on the council approvals.'
  },
  {
    question:
      'Can I <a href="/patio-builders-brisbane/">build my patio myself?</a>',
    answer: `Yes. We can arrange all approvals and delivery of materials to install. Depending on the value of materials (over $11,000) you will require an owner-builder licence.`
  },
  {
    question: 'Once my materials are delivered how long until installation?',
    answer: `We only like materials to be on site for a minimal amount of time and we try to have your project under way within one week (depending on weather conditions).`
  },
  {
    question: 'Do I need to be home during construction?',
    answer: `No. As long as our builders have access to power and the job site.`
  }
]

export default ({ title, faqs, background, ...rest }: IFAQProps) => {
  const info: IFAQ[] = faqs || data
  return (
    <FAQContainer background={background} {...rest}>
      <FAQs>
        <h3>{title}</h3>
        {info.map(faq => {
          if (faq.answer && faq.question) {
            return (
              <Accordion
                answer={faq.answer}
                key={faq.question}
                question={faq.question}
                isHtml={faq.isHtml}
              />
            )
          }
        })}
      </FAQs>
    </FAQContainer>
  )
}
